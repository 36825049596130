import React from 'react'
import StoreLocation from '../../../components/common/StoreLocation'



function Location() {
  return (
    <div className='location-container'>
        <StoreLocation/>
    </div>
  )
}

export default Location