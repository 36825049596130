import React from 'react';

const WaveDividerLayered: React.FC = () => {
  return (

    <div className='wave-page-divider'>
        <svg id="wave" style={{transform:"rotate(0deg)", transition: "0.3s"}} viewBox="0 0 1440 360" version="1.1" xmlns="http://www.w3.org/2000/svg" ><path style={{transform:"translate(0, 0px)", opacity:"1"}} fill="black" d="M0,180L48,168C96,156,192,132,288,108C384,84,480,60,576,66C672,72,768,108,864,114C960,120,1056,96,1152,108C1248,120,1344,168,1440,180C1536,192,1632,168,1728,138C1824,108,1920,72,2016,78C2112,84,2208,132,2304,150C2400,168,2496,156,2592,126C2688,96,2784,48,2880,54C2976,60,3072,120,3168,156C3264,192,3360,204,3456,228C3552,252,3648,288,3744,258C3840,228,3936,132,4032,114C4128,96,4224,156,4320,198C4416,240,4512,264,4608,282C4704,300,4800,312,4896,282C4992,252,5088,180,5184,180C5280,180,5376,252,5472,246C5568,240,5664,156,5760,126C5856,96,5952,120,6048,156C6144,192,6240,240,6336,240C6432,240,6528,192,6624,174C6720,156,6816,168,6864,174L6912,180L6912,360L6864,360C6816,360,6720,360,6624,360C6528,360,6432,360,6336,360C6240,360,6144,360,6048,360C5952,360,5856,360,5760,360C5664,360,5568,360,5472,360C5376,360,5280,360,5184,360C5088,360,4992,360,4896,360C4800,360,4704,360,4608,360C4512,360,4416,360,4320,360C4224,360,4128,360,4032,360C3936,360,3840,360,3744,360C3648,360,3552,360,3456,360C3360,360,3264,360,3168,360C3072,360,2976,360,2880,360C2784,360,2688,360,2592,360C2496,360,2400,360,2304,360C2208,360,2112,360,2016,360C1920,360,1824,360,1728,360C1632,360,1536,360,1440,360C1344,360,1248,360,1152,360C1056,360,960,360,864,360C768,360,672,360,576,360C480,360,384,360,288,360C192,360,96,360,48,360L0,360Z"></path>

        <path style={{transform:"translate(0, 50px)", opacity:"0.9"}} fill="var(--primary-color" d="M0,72L48,60C96,48,192,24,288,24C384,24,480,48,576,96C672,144,768,216,864,246C960,276,1056,264,1152,222C1248,180,1344,108,1440,72C1536,36,1632,36,1728,66C1824,96,1920,156,2016,186C2112,216,2208,216,2304,216C2400,216,2496,216,2592,210C2688,204,2784,192,2880,180C2976,168,3072,156,3168,156C3264,156,3360,168,3456,174C3552,180,3648,180,3744,180C3840,180,3936,180,4032,174C4128,168,4224,156,4320,138C4416,120,4512,96,4608,114C4704,132,4800,192,4896,204C4992,216,5088,180,5184,174C5280,168,5376,192,5472,204C5568,216,5664,216,5760,192C5856,168,5952,120,6048,138C6144,156,6240,240,6336,276C6432,312,6528,300,6624,294C6720,288,6816,288,6864,288L6912,288L6912,360L6864,360C6816,360,6720,360,6624,360C6528,360,6432,360,6336,360C6240,360,6144,360,6048,360C5952,360,5856,360,5760,360C5664,360,5568,360,5472,360C5376,360,5280,360,5184,360C5088,360,4992,360,4896,360C4800,360,4704,360,4608,360C4512,360,4416,360,4320,360C4224,360,4128,360,4032,360C3936,360,3840,360,3744,360C3648,360,3552,360,3456,360C3360,360,3264,360,3168,360C3072,360,2976,360,2880,360C2784,360,2688,360,2592,360C2496,360,2400,360,2304,360C2208,360,2112,360,2016,360C1920,360,1824,360,1728,360C1632,360,1536,360,1440,360C1344,360,1248,360,1152,360C1056,360,960,360,864,360C768,360,672,360,576,360C480,360,384,360,288,360C192,360,96,360,48,360L0,360Z"></path>

        <path style={{transform:"translate(0, 100px)"}} fill="white" d="M0,216L48,204C96,192,192,168,288,156C384,144,480,144,576,174C672,204,768,264,864,258C960,252,1056,180,1152,174C1248,168,1344,228,1440,210C1536,192,1632,96,1728,84C1824,72,1920,144,2016,162C2112,180,2208,144,2304,138C2400,132,2496,156,2592,162C2688,168,2784,156,2880,174C2976,192,3072,240,3168,234C3264,228,3360,168,3456,162C3552,156,3648,204,3744,216C3840,228,3936,204,4032,174C4128,144,4224,108,4320,96C4416,84,4512,96,4608,102C4704,108,4800,108,4896,126C4992,144,5088,180,5184,192C5280,204,5376,192,5472,210C5568,228,5664,276,5760,294C5856,312,5952,300,6048,270C6144,240,6240,192,6336,150C6432,108,6528,72,6624,78C6720,84,6816,132,6864,156L6912,180L6912,360L6864,360C6816,360,6720,360,6624,360C6528,360,6432,360,6336,360C6240,360,6144,360,6048,360C5952,360,5856,360,5760,360C5664,360,5568,360,5472,360C5376,360,5280,360,5184,360C5088,360,4992,360,4896,360C4800,360,4704,360,4608,360C4512,360,4416,360,4320,360C4224,360,4128,360,4032,360C3936,360,3840,360,3744,360C3648,360,3552,360,3456,360C3360,360,3264,360,3168,360C3072,360,2976,360,2880,360C2784,360,2688,360,2592,360C2496,360,2400,360,2304,360C2208,360,2112,360,2016,360C1920,360,1824,360,1728,360C1632,360,1536,360,1440,360C1344,360,1248,360,1152,360C1056,360,960,360,864,360C768,360,672,360,576,360C480,360,384,360,288,360C192,360,96,360,48,360L0,360Z"></path></svg>
</div>
  );
};

export default WaveDividerLayered;













